import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import {
    PAYOUT_FIELD,
    WIN_FIELD,
    TURNOVER_FIELD,
    NET_PROFIT_FIELD,
    RETURN_TO_HOUSE,
    GAMING_VARIANCE,
    addMissingFieldsWithZeroValue,
} from "./CashupDetailedReport";
import { getDates } from "./getDates";

export function GamingDetailedReportTransformer(
    detailedReportData: {
        posSplitByClassTypeData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                sumTotalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                            account: { __typename: string; name: string };
                            class: {
                                __typename: string;
                                classType: number;
                                name: string;
                            };
                        }[]
                      | undefined;
              }
            | undefined;
        cashVarianceForAllPosLocationsData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                sumTotalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                        }[]
                      | undefined;
              }
            | undefined;
        pettyCashData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                totalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                            transactions: { __typename: string; memo: string };
                        }[]
                      | undefined;
              }
            | undefined;
        depositsData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                totalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                            transactions: { __typename: string; memo: string };
                            account: { __typename: string; name: string };
                        }[]
                      | undefined;
              }
            | undefined;
        transfersData:
            | {
                  cube: {
                      __typename: string;
                      splits: {
                          __typename: string;
                          splitDate: { __typename: string; day: string };
                          totalIncTax: number;
                      };
                      transactions: { __typename: string; memo: string; id: string };
                  }[];
              }
            | undefined;
        gamingStatsData:
            | {
                  cube: {
                      __typename: string;
                      gamingStats: {
                          __typename: string;
                          date: { __typename: string; value: string; day: string };
                          turnover: number;
                          totalWins: number;
                          netProfit: number;
                          returnToHouse: number;
                      };
                  }[];
              }
            | undefined;
        gamingVarianceData:
            | {
                  cube: {
                      __typename: string;
                      splits: {
                          __typename: string;
                          sumTotalIncTax: number;
                          splitDate: { __typename: string; day: string };
                      };
                  }[];
              }
            | undefined;
        gamingPayoutsData:
            | {
                  cube: {
                      __typename: string;
                      splits: {
                          __typename: string;
                          sumTotalIncTax: number;
                          splitDate: { __typename: string; day: string };
                      };
                  }[];
              }
            | undefined;
        cashTotalsForAllPosLocationsData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                sumTotalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                        }[]
                      | undefined;
              }
            | undefined;
        cardTotalsForPosData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                sumTotalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                        }[]
                      | undefined;
              }
            | undefined;
        safeData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          safeDataTotal: number;
                          safeDataVariance: number;
                          safeSignedOff: string;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: number;
                      };
                  }[];
              }
            | undefined;
        crtData:
            | {
                  //         const { splits, account } = totalSalesItem;
                  //         const { sumTotalIncTax, splitDate } = splits;
                  //         const { day } = splitDate;
                  //
                  //         console.log("Account name: ", account.name, " : ", sumTotalIncTax);
                  //
                  //         if (!DataMap[day]) {
                  //             DataMap[day] = {};
                  //             DataMap[day][account.name] = sumTotalIncTax;
                  //         } else {
                  //             if (!DataMap[day][account.name]) {
                  //                 DataMap[day][account.name] = sumTotalIncTax;
                  //             } else {
                  //                 DataMap[day][account.name] += sumTotalIncTax;
                  //             }
                  //         }
                  //     }
                  // );
                  cube: {
                      __typename: string;
                      cashup: {
                          //         const { sumTotalIncTax, splitDate } = splits;
                          //         const { day } = splitDate;
                          //
                          //         console.log("Account name: ", account.name, " : ", sumTotalIncTax);
                          //
                          //         if (!DataMap[day]) {
                          //             DataMap[day] = {};
                          //             DataMap[day][account.name] = sumTotalIncTax;
                          //         } else {
                          //             if (!DataMap[day][account.name]) {
                          //                 DataMap[day][account.name] = sumTotalIncTax;
                          //             } else {
                          //                 DataMap[day][account.name] += sumTotalIncTax;
                          //             }
                          //         }
                          //     }
                          // );
                          __typename: string;
                          shiftDate: {
                              //         const { day } = splitDate;
                              //
                              //         console.log("Account name: ", account.name, " : ", sumTotalIncTax);
                              //
                              //         if (!DataMap[day]) {
                              //             DataMap[day] = {};
                              //             DataMap[day][account.name] = sumTotalIncTax;
                              //         } else {
                              //             if (!DataMap[day][account.name]) {
                              //                 DataMap[day][account.name] = sumTotalIncTax;
                              //             } else {
                              //                 DataMap[day][account.name] += sumTotalIncTax;
                              //             }
                              //         }
                              //     }
                              // );
                              __typename: string;
                              day: string; //         console.log("Account name: ", account.name, " : ", sumTotalIncTax);
                          };
                          cashCountCashVariance: number;
                          atmDataRefills: number | null;
                          atmDataWithdrawls: number;
                      };
                      location: {
                          //             DataMap[day][account.name] = sumTotalIncTax;
                          //         } else {
                          //             if (!DataMap[day][account.name]) {
                          //                 DataMap[day][account.name] = sumTotalIncTax;
                          //             } else {
                          //                 DataMap[day][account.name] += sumTotalIncTax;
                          //             }
                          //         }
                          //     }
                          // );
                          __typename: string;
                          name: string;
                          locationType: number;
                      }; //             if (!DataMap[day][account.name]) {
                  }[];
              }
            | undefined;
        atmData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          cashCountCashVariance: number;
                          atmDataRefills: number;
                          atmDataWithdrawls: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: number;
                      };
                  }[];
              }
            | undefined;
        wageringData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          wageringDataSalesTotal: number;
                          wageringDataCommission: number;
                          cashCountCashVariance: number; // TODO Double check we absolutely need this
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: number;
                      }; //     (totalSalesItem) => {
                  }[];
              }
            | undefined;
        wageringPayoutsData:
            | {
                  cube: {
                      __typename: string;
                      splits: {
                          __typename: string;
                          sumTotalIncTax: number;
                          splitDate: {
                              __typename: string; //             }

                              //             }
                              //         }
                              //     }
                              // );
                              day: string;
                          }; //     }
                      }; // );

                      // );
                      location: {
                          __typename: string;
                          name: string;
                          locationType: number;
                      };
                  }[];
              }
            | undefined;
        bankableTradeTillData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          cashCountExpectedCash: number;
                          cashCountCashVariance: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: number;
                      };
                  }[];
              }
            | undefined;
        bankableTradeTillTabData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          cashCountExpectedCash: number;
                          cashCountCashVariance: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: number;
                      };
                  }[];
              }
            | undefined;
        bankableTradeTillKenoData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          cashCountExpectedCash: number;
                          cashCountCashVariance: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: number;
                      };
                  }[];
              }
            | undefined;
        posParentLocationsData: {
            [parentLocationId: string]: {
                sales: {
                    cube: {
                        splits: {
                            sumTotalIncTax: number;
                            splitDate?: { day: string };
                        };
                        account: { name: string };
                        class: { classType: number; name: string };
                    }[];
                };
                card: {
                    cube: {
                        splits: {
                            sumTotalIncTax: number;
                            splitDate: { day: string };
                        };
                    }[];
                };
                cashVariance: {
                    cube: {
                        splits: {
                            sumTotalIncTax: number;
                            splitDate: { day: string };
                        };
                    }[];
                };
                cash: {
                    cube: {
                        splits: {
                            sumTotalIncTax: number;
                            splitDate: { day: string };
                        };
                    }[];
                };
            };
        };
    },
    selectedRange: { start: string; end: string } | undefined
) {
    if (!detailedReportData || !selectedRange) {
        return {};
    }

    const knownGamingColumns: string[] = [];
    const DataMap: any = {};

    detailedReportData.gamingPayoutsData?.cube.forEach((payoutItem) => {
        const { splits } = payoutItem;
        const { sumTotalIncTax, splitDate } = splits;
        const { day } = splitDate;

        if (!DataMap[day]) {
            DataMap[day] = {};
            DataMap[day][PAYOUT_FIELD] = -sumTotalIncTax;
        } else {
            if (!DataMap[day][PAYOUT_FIELD]) {
                DataMap[day][PAYOUT_FIELD] = -sumTotalIncTax;
            } else {
                DataMap[day][PAYOUT_FIELD] += -sumTotalIncTax;
            }
        }
    });

    detailedReportData.gamingStatsData?.cube.forEach((gamingStatsItem) => {
        const { gamingStats } = gamingStatsItem;
        const { totalWins, turnover, netProfit, date, returnToHouse } = gamingStats;
        const { day } = date;

        if (!DataMap[day]) {
            DataMap[day] = {};
            DataMap[day][WIN_FIELD] = totalWins;
            DataMap[day][TURNOVER_FIELD] = turnover;
            DataMap[day][NET_PROFIT_FIELD] = netProfit;
            DataMap[day][RETURN_TO_HOUSE] = returnToHouse;
        } else {
            if (!DataMap[day][WIN_FIELD]) {
                DataMap[day][WIN_FIELD] = totalWins;
            } else {
                DataMap[day][WIN_FIELD] += totalWins;
            }

            if (!DataMap[day][TURNOVER_FIELD]) {
                DataMap[day][TURNOVER_FIELD] = turnover;
            } else {
                DataMap[day][TURNOVER_FIELD] += turnover;
            }

            if (!DataMap[day][NET_PROFIT_FIELD]) {
                DataMap[day][NET_PROFIT_FIELD] = netProfit;
            } else {
                DataMap[day][NET_PROFIT_FIELD] += netProfit;
            }

            if (!DataMap[day][RETURN_TO_HOUSE]) {
                DataMap[day][RETURN_TO_HOUSE] = returnToHouse;
            } else {
                DataMap[day][RETURN_TO_HOUSE] += returnToHouse;
            }
        }
    });

    detailedReportData.gamingVarianceData?.cube.forEach((gamingVarianceItem) => {
        const { splits } = gamingVarianceItem;
        const { sumTotalIncTax, splitDate } = splits;
        const { day } = splitDate;

        if (!DataMap[day]) {
            DataMap[day] = {};
            DataMap[day][GAMING_VARIANCE] = sumTotalIncTax;
        } else {
            if (!DataMap[day][GAMING_VARIANCE]) {
                DataMap[day][GAMING_VARIANCE] = sumTotalIncTax;
            } else {
                DataMap[day][GAMING_VARIANCE] += sumTotalIncTax;
            }
        }
    });

    if (knownGamingColumns.indexOf(TURNOVER_FIELD) === -1) {
        knownGamingColumns.push(TURNOVER_FIELD);
    }
    if (knownGamingColumns.indexOf(WIN_FIELD) === -1) {
        knownGamingColumns.push(WIN_FIELD);
    }

    if (knownGamingColumns.indexOf(PAYOUT_FIELD) === -1) {
        knownGamingColumns.push(PAYOUT_FIELD);
    }

    if (knownGamingColumns.indexOf(NET_PROFIT_FIELD) === -1) {
        knownGamingColumns.push(NET_PROFIT_FIELD);
    }

    if (knownGamingColumns.indexOf(RETURN_TO_HOUSE) === -1) {
        knownGamingColumns.push(RETURN_TO_HOUSE);
    }

    if (knownGamingColumns.indexOf(GAMING_VARIANCE) === -1) {
        knownGamingColumns.push(GAMING_VARIANCE);
    }

    const Dates = Object.keys(DataMap).sort(function (a, b) {
        return Date.parse(a) - Date.parse(b);
    });

    const filledInDates = getDates(selectedRange.start, selectedRange.end);

    const filledDataMap = filledInDates.reduce((acc, date) => {
        const tempDateObject = date.toDate();
        tempDateObject.setUTCHours(0, 0, 0, 0);
        const dateString = tempDateObject.toISOString();
        if (Dates.includes(dateString)) {
            acc[dateString] = addMissingFieldsWithZeroValue(
                knownGamingColumns,
                DataMap[dateString]
            );
        } else {
            acc[dateString] = addMissingFieldsWithZeroValue(knownGamingColumns, {});
        }
        return acc;
    }, {} as any);

    const tableData: any = [];

    Object.keys(filledDataMap).forEach((currentDate) => {
        tableData.push({ currentDate, ...filledDataMap[currentDate] });
    });

    const tableColumns: ColumnsType<any> = [
        {
            title: "Date",
            dataIndex: "currentDate",
            key: "currentDate",
            fixed: "left",
            width: 140,
            render: (date: string) => {
                return dayjs(date).format("ddd DD/MM/YYYY");
            },
        },
        ...knownGamingColumns.map((column) => {
            if (column === RETURN_TO_HOUSE) {
                return {
                    title: column,
                    dataIndex: column,
                    key: column,
                    render: (value: number) => {
                        return value ? `${value.toFixed(2)}%` : "-";
                    },
                };
            }

            return {
                title: column,
                dataIndex: column,
                key: column,
                render: (value: number) => {
                    return value ? `$${value.toFixed(2)}` : "-";
                },
            };
        }),
    ];

    return { filledDataMap, knownGamingColumns, tableData, tableColumns };
}
