import { useMemo } from "react";
import { CubeName, CubeQueryBuilder, useCubejs } from "utils/cubejs-utils";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { chain } from "lodash";
import { ShiftReportPromptRecordService } from "Services/ShiftReportPromptRecordService";
import produce from "immer";
import { dayjsFormat, toQTCDate } from "utils/date-utils";

interface GetShiftReportPrompt {
    date: string;
    venueId: string;
}

export enum FieldType {
    TEXT = 1,
    BOOLEAN = 2,
    NUMBER = 3,
    CURRENCY = 4,
    PERCENTAGE = 5,
    DATETIME = 6,
    BIGTEXT = 7,
}

export interface ShiftReportPromptRecord {
    id: string;
    date: string;
    body: string;
    promptId: string;
    promptType: number; // FieldType
}
const QUERY_KEY = "SHIFT_REPORT_PROMPT_RECORDS";

export const useListShiftReportPromptRecords = ({
    date,
    venueId,
}: GetShiftReportPrompt) => {
    const { cubejsApi } = useCubejs();
    const correctDateFormat = useMemo(
        () =>
            toQTCDate(date, dayjsFormat.dayMonthYearSeparateByDash).format(
                dayjsFormat.default.format
            ),
        [date]
    );

    const query = CubeQueryBuilder({
        dimensions: [
            `${CubeName.shiftReportPromptRecord}.id`,
            `${CubeName.shiftReportPromptRecord}.promptId`,
            `${CubeName.shiftReportPromptRecord}.date`,
            `${CubeName.shiftReportPromptRecord}.body`,
        ],
        filters: [
            {
                member: `${CubeName.shiftReportPromptRecord}.date`,
                operator: "equals",
                values: [correctDateFormat],
            },
            {
                member: `${CubeName.venueShiftReportPrompt}.venueId`,
                operator: "equals",
                values: [venueId],
            },
        ],
    }).getResult();

    const { isLoading, data: shiftReportPromptRecords } = useQuery(
        [QUERY_KEY, { date, venueId }],
        () =>
            cubejsApi!.load(query).then((res) =>
                res.rawData().map(
                    (d) =>
                        ({
                            id: d[`${CubeName.shiftReportPromptRecord}.id`],
                            date: d[`${CubeName.shiftReportPromptRecord}.date`],
                            body: d[`${CubeName.shiftReportPromptRecord}.body`],
                            promptId:
                                d[`${CubeName.shiftReportPromptRecord}.promptId`],
                        } as ShiftReportPromptRecord)
                )
            ),
        {
            enabled: Boolean(cubejsApi),
            staleTime: Infinity,
        }
    );

    const shiftReportPromptRecordsByPromptId = useMemo(() => {
        return shiftReportPromptRecords
            ? chain(shiftReportPromptRecords).keyBy("promptId").mapValues().value()
            : undefined;
    }, [shiftReportPromptRecords]);

    return {
        shiftReportPromptRecords,
        isLoading,
        shiftReportPromptRecordsByPromptId,
    };
};

export const useCreateShiftReportPromptRecord = ({
    date,
    venueId,
}: GetShiftReportPrompt) => {
    const queryClient = useQueryClient();
    const queryKey = [QUERY_KEY, { date, venueId }];
    return useMutation(ShiftReportPromptRecordService.create, {
        onMutate: async ({ promptId, body, date }) => {
            await queryClient.cancelQueries(queryKey);

            const previousPromptRecords = queryClient.getQueryData(
                queryKey
            ) as ShiftReportPromptRecord[];

            queryClient.setQueryData(queryKey, (old: any) => {
                return [...old, { body, promptId, date, id: "-1" }];
            });

            return { previousPromptRecords };
        },
        onError: (err, _, context: any) => {
            queryClient.setQueryData(queryKey, context?.previousPromptRecords);
        },
        onSettled: (data) => {
            queryClient.setQueryData(queryKey, (old: any) => {
                return produce(old, (draft: ShiftReportPromptRecord[]) => {
                    const promptRecord = draft.find(
                        ({ promptId }) => promptId === data.prompt
                    );
                    promptRecord!.id = data.id;
                });
            });
        },
    });
};

export const useUpdateShiftReportPromptRecord = ({
    date,
    venueId,
}: GetShiftReportPrompt) => {
    const queryClient = useQueryClient();
    const queryKey = [QUERY_KEY, { date, venueId }];
    return useMutation(ShiftReportPromptRecordService.update, {
        onMutate: async ({ body, promptRecordId }) => {
            // await queryClient.cancelQueries(queryKey);

            const previousPromptRecords = queryClient.getQueryData(queryKey);

            queryClient.setQueryData(queryKey, (old: any) => {
                return produce(old, (draft: ShiftReportPromptRecord[]) => {
                    const promptRecord = draft.find(
                        ({ id }) => id === promptRecordId
                    );
                    promptRecord!.body = body;
                });
            });

            return { previousPromptRecords };
        },
        onError: (err, newTodo, context: any) => {
            queryClient.setQueryData(queryKey, context?.previousPromptRecords);
        },
    });
};
