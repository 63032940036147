import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, Modal } from "antd";
import React, {
    Dispatch,
    ReactElement,
    SetStateAction,
    useEffect,
    useState,
} from "react";
import { useHistory } from "react-router-dom";
import { Auth, getUserToken, isAuthenticated } from "utils/auth0/auth";
import { sendPasswordResetEmail } from "utils/auth0/auth0API";
import { Auth0Result, AuthTokens } from "utils/auth0/types";
import { RoutePaths } from "../../Router/RoutesEnum";
import { useQuery } from "../../utils/routing";
import { CardComponent, CenteredSingleDiv, LogoComponent } from "../CenteredCard";

const LoginForm = (): ReactElement => {
    const router = useHistory();
    const query = useQuery();
    const authZero = new Auth();

    const [resetPasswordFlag, setResetPasswordFlag] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        document.title = "Cashup - Login";
    }, []);

    useEffect(() => {
        const accessToken = getUserToken(AuthTokens.accessToken);
        const authenticated = isAuthenticated();
        if (accessToken && authenticated) {
            router.push(RoutePaths.HOME);
        }
    }, [router]);

    const onFinish = async (values: { email: string; password?: string }) => {
        setLoading(true);

        if (resetPasswordFlag) {
            // Handle password reset requests
            await sendPasswordResetEmail(values.email).catch(() => {
                // TODO LOG THIS EVENT
                // Note: This pretends that the email being requests exists regardless of fact.
                // The idea is we prevent unauthorised parties for being able to extract information on which users (emails) do & don't exists in our system.
            });
            Modal.success({
                title: "Password reset requested",
                content: (
                    <div>
                        <p>
                            Please check the inbox of the associated email for the
                            password reset link.
                        </p>
                    </div>
                ),
                onOk() {
                    router.push(RoutePaths.LOGIN);
                },
            });
            setLoading(false);
        } else {
            // Handle login events.
            try {
                const OUTCOME = (await authZero.signin(
                    values.email,
                    values.password! // If the resetPasswordFlag is false the "password" value is always present
                )) as Auth0Result;

                // Emit event to inform the rest of the app that the user has logged in.
                window.dispatchEvent(new Event("login"));
                if (OUTCOME.accessToken) {
                    // Successful login - Navigates user to the home screen.
                    setLoading(false);

                    const REFERRING_PAGE = query.get("referrer");
                    if (REFERRING_PAGE) {
                        router.push(REFERRING_PAGE);
                    } else {
                        router.push(RoutePaths.HOME);
                    }
                } else {
                    // Edge case. Firebase will raise an exception rather than return a null/undefined object.
                    setLoading(false);
                    Modal.error({
                        title: "Log-in error1",
                        content:
                            "Please ensure your password and email are correct.",
                    });
                }
            } catch (e) {
                // Login in exceptions.
                // This is how Firebase handles incorrect credentials.
                setLoading(false);
                Modal.error({
                    title: "Log-in error",
                    content: "Please ensure your password and email are correct.",
                });
            }
        }
    };

    return (
        <CenteredSingleDiv>
            <CardComponent>
                <LogoComponent>
                    <img
                        alt="cashup.com.au"
                        src="/Cashup.png"
                        width="200" // Sets the width to 200 & maintains aspect ratio.
                    />
                </LogoComponent>
                <Divider />
                <FormComponent
                    resetPasswordFlag={resetPasswordFlag}
                    setResetPasswordFlag={setResetPasswordFlag}
                    onFinish={onFinish}
                    loading={loading}
                />
            </CardComponent>
        </CenteredSingleDiv>
    );
};

const FormComponent = ({
    resetPasswordFlag,
    setResetPasswordFlag,
    onFinish,
    loading,
}: {
    resetPasswordFlag: boolean;
    setResetPasswordFlag: Dispatch<SetStateAction<boolean>>;
    onFinish: (value: any) => void;
    loading: boolean;
}) => {
    if (resetPasswordFlag) {
        return (
            <Form
                name="normal_login"
                style={{ maxWidth: "100%" }}
                onFinish={onFinish}
            >
                <Form.Item
                    name="email"
                    rules={[
                        {
                            required: true,
                            type: "email",
                            message: "Please input a valid email!",
                        },
                    ]}
                >
                    <Input prefix={<UserOutlined />} placeholder="E-mail Address" />
                </Form.Item>
                <Form.Item>
                    <a onClick={() => setResetPasswordFlag(false)}>
                        Go back to login
                    </a>
                </Form.Item>
                <Divider />

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{ width: "100%" }}
                        loading={loading}
                    >
                        Reset password
                    </Button>
                </Form.Item>
            </Form>
        );
    } else {
        return (
            <Form
                name="normal_login"
                style={{ maxWidth: "100%" }}
                onFinish={onFinish}
            >
                <Form.Item
                    name="email"
                    rules={[
                        {
                            required: true,
                            type: "email",
                            message: "Please input a valid email!",
                        },
                    ]}
                >
                    <Input prefix={<UserOutlined />} placeholder="E-mail Address" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: "Please input your Password!",
                        },
                    ]}
                >
                    <Input
                        prefix={<LockOutlined />}
                        type="password"
                        placeholder="Password"
                    />
                </Form.Item>
                <Form.Item>
                    <a onClick={() => setResetPasswordFlag(true)}>
                        Forgot password?
                    </a>
                </Form.Item>
                <Divider />

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{ width: "100%" }}
                        loading={loading}
                    >
                        Log in
                    </Button>
                </Form.Item>
            </Form>
        );
    }
};

export { LoginForm };
