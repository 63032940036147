import { getDates } from "./getDates";
import {
    VARIANCE,
    BANKABLE,
    addMissingFieldsWithZeroValue,
} from "./CashupDetailedReport";

export function TradeTillDetailedReportTransformer(
    detailedReportData: {
        posSplitByClassTypeData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                sumTotalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                            account: { __typename: string; name: string };
                            class: {
                                __typename: string;
                                classType: number;
                                name: string;
                            };
                        }[]
                      | undefined;
              }
            | undefined;
        cashVarianceForAllPosLocationsData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                sumTotalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                        }[]
                      | undefined;
              }
            | undefined;
        pettyCashData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                totalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                            transactions: { __typename: string; memo: string };
                        }[]
                      | undefined;
              }
            | undefined;
        depositsData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                totalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                            transactions: { __typename: string; memo: string };
                            account: { __typename: string; name: string };
                        }[]
                      | undefined;
              }
            | undefined;
        transfersData:
            | {
                  cube: {
                      __typename: string;
                      splits: {
                          __typename: string;
                          splitDate: { __typename: string; day: string };
                          totalIncTax: number;
                      };
                      transactions: { __typename: string; memo: string; id: string };
                  }[];
              }
            | undefined;
        gamingStatsData:
            | {
                  cube: {
                      __typename: string;
                      gamingStats: {
                          __typename: string;
                          date: { __typename: string; value: string; day: string };
                          turnover: number;
                          totalWins: number;
                          netProfit: number;
                          returnToHouse: number;
                      };
                  }[];
              }
            | undefined;
        gamingVarianceData:
            | {
                  cube: {
                      __typename: string;
                      splits: {
                          __typename: string;
                          sumTotalIncTax: number;
                          splitDate: { __typename: string; day: string };
                      };
                  }[];
              }
            | undefined;
        gamingPayoutsData:
            | {
                  cube: {
                      __typename: string;
                      splits: {
                          __typename: string;
                          sumTotalIncTax: number;
                          splitDate: { __typename: string; day: string };
                      };
                  }[];
              }
            | undefined;
        cashTotalsForAllPosLocationsData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                sumTotalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                        }[]
                      | undefined;
              }
            | undefined;
        cardTotalsForPosData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                sumTotalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                        }[]
                      | undefined;
              }
            | undefined;
        safeData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          safeDataTotal: number;
                          safeDataVariance: number;
                          safeSignedOff: string;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: number;
                      };
                  }[];
              }
            | undefined;
        crtData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          cashCountCashVariance: number;
                          atmDataRefills: number | null;
                          atmDataWithdrawls: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: number;
                      };
                  }[];
              }
            | undefined;
        atmData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          cashCountCashVariance: number;
                          atmDataRefills: number;
                          atmDataWithdrawls: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: number;
                      };
                  }[];
              }
            | undefined;
        wageringData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          wageringDataSalesTotal: number;
                          wageringDataCommission: number;
                          cashCountCashVariance: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: number;
                      };
                  }[];
              }
            | undefined;
        wageringPayoutsData:
            | {
                  cube: {
                      __typename: string;
                      splits: {
                          __typename: string;
                          sumTotalIncTax: number;
                          splitDate: { __typename: string; day: string };
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: number;
                      };
                  }[];
              }
            | undefined;
        bankableTradeTillData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          cashCountExpectedCash: number;
                          cashCountCashVariance: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: number;
                      };
                  }[];
              }
            | undefined;
        bankableTradeTillTabData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          cashCountExpectedCash: number;
                          cashCountCashVariance: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: number;
                      };
                  }[];
              }
            | undefined;
        bankableTradeTillKenoData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          cashCountExpectedCash: number;
                          cashCountCashVariance: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: number;
                      };
                  }[];
              }
            | undefined;
        posParentLocationsData: {
            [parentLocationId: string]: {
                sales: {
                    cube: {
                        splits: {
                            sumTotalIncTax: number;
                            splitDate?: { day: string };
                        };
                        account: { name: string };
                        class: { classType: number; name: string };
                    }[];
                };
                card: {
                    cube: {
                        splits: {
                            sumTotalIncTax: number;
                            splitDate: { day: string };
                        };
                    }[];
                };
                cashVariance: {
                    cube: {
                        splits: {
                            sumTotalIncTax: number;
                            splitDate: { day: string };
                        };
                    }[];
                };
                cash: {
                    cube: {
                        splits: {
                            sumTotalIncTax: number;
                            splitDate: { day: string };
                        };
                    }[];
                };
            };
        };
    },
    selectedRange: { start: string; end: string } | undefined
) {
    if (!detailedReportData || !selectedRange) {
        return {};
    }

    const DataMap: any = {};
    const knownTradeTillColumns: string[] = [];

    detailedReportData.bankableTradeTillData?.cube.forEach((crtItem) => {
        const { cashup } = crtItem;
        const { shiftDate, cashCountCashVariance, cashCountExpectedCash } = cashup;

        const { day } = shiftDate;

        if (!DataMap[day]) {
            DataMap[day] = {};
            DataMap[day][VARIANCE] = cashCountCashVariance;
            DataMap[day][BANKABLE] = cashCountExpectedCash;
        } else {
            DataMap[day][VARIANCE] += cashCountCashVariance;
            DataMap[day][BANKABLE] += cashCountExpectedCash;
        }
    });

    if (knownTradeTillColumns.indexOf(BANKABLE) === -1) {
        knownTradeTillColumns.push(BANKABLE);
    }

    if (knownTradeTillColumns.indexOf(VARIANCE) === -1) {
        knownTradeTillColumns.push(VARIANCE);
    }

    const Dates = Object.keys(DataMap).sort(function (a, b) {
        return Date.parse(a) - Date.parse(b);
    });

    const filledInDates = getDates(selectedRange.start, selectedRange.end);

    const filledInDataMap = filledInDates.reduce((acc, date) => {
        const tempDateObject = date.toDate();
        tempDateObject.setUTCHours(0, 0, 0, 0);
        const dateString = tempDateObject.toISOString();
        if (Dates.includes(dateString)) {
            acc[dateString] = addMissingFieldsWithZeroValue(
                knownTradeTillColumns,
                DataMap[dateString]
            );
        } else {
            acc[dateString] = addMissingFieldsWithZeroValue(
                knownTradeTillColumns,
                {}
            );
        }
        return acc;
    }, {} as any);

    const tableData = Object.keys(filledInDataMap).map((date) => {
        return {
            key: date,
            ...filledInDataMap[date],
        };
    });

    return {
        tableData,
        knownTradeTillColumns,
    };
}
